import "./form_input.css"
import FormRange from "./range/form_range";
import FormRadio from "./radio_group/form_radio";
import React, {useState} from "react";
import SelectMultiple from "./select-multiple/select-multiple";
const FormInput = (props) => {
    let {
        selectedWorkers, setSelectedWorkers,
        dateValue,checkWorkerField,getWorkersLoading,readyWorkers,label, errorMessage,
        onChange,type,values,valuesTitle,init_value, ...inputProps } = props;
    const [focused, setFocused] = useState(false);
    const handleFocus = (e) => {
        setFocused(true);
    };

    return (
            <div className="formInput">
                {type !== "radio"?<label>{label}</label>:valuesTitle?<label>{label}</label>:null}
                {type === "select" ?
                    !dateValue && checkWorkerField(inputProps.categid)?
                        <div style={{fontSize:12,color:"red"}}>
                            حدد تاريخ للتأكد مما اذا كانت العاملات متاحات
                        </div>:
                getWorkersLoading && checkWorkerField(inputProps.categid)?
                    <div style={{fontSize:12,color:"grey"}}>تحديث العاملات ..</div>
                    :readyWorkers.length === 0 && checkWorkerField(inputProps.categid)?
                        <div style={{fontSize:12,color:"red"}}>
                            تم حجز جميع العاملات في هذه الفترة وبهذا التاريخ, يمكنك تحديد فترة او تاريخ اخر
                        </div>
                        :checkWorkerField(inputProps.categid) ?
                        <SelectMultiple
                            selectedWorkers={selectedWorkers}
                            setSelectedWorkers={setSelectedWorkers}
                            optionsData={readyWorkers}
                        />:
                        <select
                            {...inputProps}
                            onChange={onChange}
                        >
                            {values.map(value => (
                                <option key={value.id} value={value.value}>{value.name}</option>))
                            }
                        </select>:
                        type === "range"?
                        <FormRange
                            {...props}
                            onChange={onChange}
                            valuesTitle={valuesTitle}
                            init_value={init_value}
                            checkWorkerField={checkWorkerField}
                            getWorkersLoading={getWorkersLoading}
                        />
                        :
                    type === "radio"?
                        <FormRadio
                            {...props}
                            onChange={onChange}
                            valuesTitle={valuesTitle}
                        />
                        :
                        type === "textarea"?
                            <textarea
                                {...inputProps}
                                onChange={onChange}
                                onBlur={handleFocus}
                                focused={focused.toString()}
                            />:
                    <input
                        type={type}
                        {...inputProps}
                        onChange={onChange}
                        onBlur={handleFocus}
                        focused={focused.toString()}
                    />
                }
                <span className="span-error">{errorMessage}</span>
            </div>
    );
};

export default FormInput;
