import './header_home.css'
import {Link} from "react-router-dom";
function HeaderHome() {
    return (
        <div className="header home">
            {/*<Soap/>*/}
            <div className="container-fluid">
                <div className="hero">
                    <div>
                        <div className="title">الأمان </div>
                        <h2>للتنظيفات </h2>
                    </div>
                    <h3>افضل شركة نظافة في قطر</h3>
                    {/*<ImageSlider />*/}
                    <br/>
                    {/*<p>آخر اعمالنا ..</p>*/}
                    {/*<br/>*/}
                    <Link className="btn" to="/booking">اطلب الان</Link>
                </div>
            </div>
        </div>
    );
}

export default HeaderHome;
