import "./form_range.css"
import React, {useState} from "react";

const FormRange = (props) => {
    const {label, errorMessage, onChange, id,value,valuesTitle,init_value, ...inputProps } = props;
    const [focused, setFocused] = useState(false);
    const handleFocus = (e) => {
        setFocused(true);
    };

    return (
        <div className="field">
                <input
                    {...inputProps}
                    value={value ? value : init_value ? init_value : 0}
                    min={init_value ? init_value : "0"}
                    max="10"
                    onChange={onChange}
                    onBlur={handleFocus}
                    focused={focused.toString()}
                    required
                />
                <div>
                    {value?value:init_value?init_value:0}
                </div>
            </div>
    );
};

export default FormRange;
