import { Routes, Route, HashRouter} from "react-router-dom";
import HomePage from "./Home/home_page";
import OrderSent from "./pages/booking/order_sent/order_sent";
import Booking from "./pages/booking/booking";
import Login from "./components/user/login/login";
import Registration from "./components/user/registration/registration";
import ForgetPass from "./components/user/forget_pass/forget_pass";
import Profile from "./components/user/profile/profile";
import Invoice from "./components/invoice/invoice";
import LastOrders from "./components/user/profile/last_orders/last_orders";
import './App.css';

function App() {
  return (
    <div>
        {/*<ImageSlider />*/}
        <HashRouter>
            <Routes>
                <Route path="*" element={<HomePage />} />
                <Route exact path='/booking' element={<Booking />}></Route>
                <Route exact path='/' element={<HomePage />}></Route>
                <Route exact path='/order_sent/:type/:id/:total/:paytype' element={<OrderSent />}></Route>
                <Route exact path='/login' element={<Login />}></Route>
                <Route exact path='/registration/:code/:email' element={<Registration />}></Route>
                <Route exact path='/forget_password/:code/:email' element={<ForgetPass />}></Route>
                <Route exact path='/profile' element={<Profile />}></Route>
                <Route exact path='/invoice/:id' element={<Invoice />}></Route>
                <Route exact path='/last_orders' element={<LastOrders />}></Route>
            </Routes>
        </HashRouter>
    </div>
  );
}

export default App;
