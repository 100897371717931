import React, {useEffect, useState} from 'react';
import "./invoice.css"
import logo from "../../assets/images/header/alamanlogo.png"
import axios from "axios";
import {baseUrl, paymentUrl} from "../../common/const/consts";
import { useParams} from "react-router-dom";
import qrCities from "../../common/const/qa_new.json"
import BackButton from "../back/back-button";
import InnerNavbar from "../navbar/inner-navbar/inner-nav";
const Invoice = () => {
    const [loading, setLoading] = useState(true)
    const [payLoading, setPayLoading] = useState(false)
    const [data, setData] = useState({})
    const {id} = useParams()
    useEffect(()=> {
        getData()
    },[])

    const getCity = (city) => {
        const list = qrCities
        const newList = []
        list.forEach((value)=>{
            newList.push(value)
        })
        return newList.find(value => value.id === +city).name
    }
    const getData = () => {
        setLoading(true)
        axios.post(`${baseUrl}select/orders/`, {orderId: id}).then(value => {
            console.log(value.data)
            setData(value.data[0])
            setLoading(false)
        })
    }
    const printInvoice = () => {
        window.print();
    }
    const getSumItem = (price,value) => {
        let total = 0
        if (typeof value === "number"){
            total = value * price
        }
        return total?total:0
    }

    const checkout = () => {
        setPayLoading(true)
        const body = {orderId:id}
        // console.log(JSON.stringify(body))
        axios.post(paymentUrl,body).then(value => {
            // console.log(value.data)
            // window.open(value.data, '_self');
            window.location.href = value.data;
            setPayLoading(false)
        })
    }
    return (
        <div>
            <InnerNavbar />
            <BackButton title="تفاصيل الفاتورة" />
            <div className= "invoice-wrapper" id = "print-area">
                {loading?<div className="text-center mt-5">تحميل الفاتورة ..</div>:
                !data?<div className="text-center mt-5">لا تتوفر فاتورة بهذا الرقم</div>:
                    <div className= "invoice">
                        <div className={+data.payment_status === 2?"pay-status green":"pay-status orange"}>
                            {+data.payment_status === 2?"مسددة":"مستحقة"}
                            <i style={{fontSize:"15px"}} className={+data.payment_status === 2?
                                "fa-solid fa-check pt-1":"fa-solid fa-circle-exclamation"}></i>
                        </div>
                        <div className= "invoice-container">
                            <div className= "invoice-head">
                                <div className= "invoice-head-top">
                                    <div className= "invoice-head-top-left text-start">
                                        <img src={logo}  alt="logo"/>
                                    </div>
                                    <div className= "invoice-head-top-right text-end">
                                        <h3>فاتورة</h3>
                                    </div>
                                </div>
                                <div className= "hr"></div>
                                <div className= "invoice-head-middle">
                                    <div className= "invoice-head-middle-left text-start">
                                        <p><span className= "text-bold">التاريخ</span>: {data.created_at}{" "}{data.create_at_day}</p>
                                    </div>
                                    <div className= "invoice-head-middle-right text-end">
                                        <p><span className= "text-bold">رقم الفاتورة:</span>{" "+data.doc_id}</p>
                                    </div>
                                </div>
                                <div className= "invoice-head-middle">
                                    <div className= "invoice-head-middle-left text-start">
                                        <p><span className= "text-bold">تاريخ الحجز</span>: {data.booked_date}</p>
                                    </div>
                                    <div className="invoice-head-middle-right text-end">
                                        <p><span className="text-bold">نوع الدفع:</span>
                                            {+data.payment_type === 2?"الكتروني" : "كاش"}
                                        </p>
                                    </div>
                                </div>
                                <div className= "hr"></div>
                                <div className= "invoice-head-bottom">
                                    <div className= "invoice-head-bottom-left">
                                        <ul>
                                            <li className= 'text-bold'>الي السيد/السادة:</li>
                                            <li>{data.user?.name}</li>
                                            <li>{getCity(data.city)}</li>
                                            <li>{data.address}</li>
                                            {/*<li>United Kingdom</li>*/}
                                        </ul>
                                    </div>
                                    <div className= "invoice-head-bottom-right">
                                        <ul className= "text-end">
                                            <li className= 'text-bold'>تدفع الي:</li>
                                            <li>Alaman for cleaning</li>
                                            <li>Qatar</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className= "overflow-view">
                                <div className= "invoice-body">
                                    <table>
                                        <thead>
                                        <tr>
                                            <td className= "text-bold">الخدمة</td>
                                            <td className= "text-bold">البيان</td>
                                            <td className= "text-bold">السعر</td>
                                            <td className= "text-bold">المجموع</td>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {data.services.map((value,index) =>
                                            <tr key={index}>
                                                <td>{value.service_id}</td>
                                                <td>{value.service_value}</td>
                                                <td>{value.price}</td>
                                                <td>{getSumItem(+value.price,1)}</td>
                                            </tr>)}
                                        </tbody>
                                    </table>
                                    <div className= "invoice-body-bottom">
                                        <div className= "invoice-body-info-item border-bottom">
                                            <div className= "info-item-td text-end text-bold">المجموع:</div>
                                            <div className= "info-item-td text-bold">{data.total} QAR</div>
                                        </div>
                                        <div className= "invoice-body-info-item border-bottom">
                                            <div className= "info-item-td text-end text-bold">تخفيض:</div>
                                            <div className= "info-item-td text-bold">{data.discount} QAR</div>
                                        </div>
                                        <div className= "invoice-body-info-item">
                                            <div className= "info-item-td text-end text-bold">الإجمالي:</div>
                                            <div className= "info-item-td text-bold f-20">{data.total - data.discount} QAR</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className= "invoice-foot text-center">
                                {/*<p><span className= "text-bold text-center">NOTE:&nbsp;</span>This is computer generated receipt and does not require physical signature.</p>*/}

                                <div className= "invoice-btns">
                                    {+data.payment_status !== 2  && +data.payment_type === 2 &&
                                       <button onClick={checkout} type="button" className={payLoading?"invoice-btn payment opacity-50":"invoice-btn payment"}>
                                           {!payLoading && <span>
                                            <i style={{fontSize: "16px", marginLeft: "10px"}}
                                               className="fa-solid fa-money-bill-1"></i>
                                             </span>
                                           }
                                                <span>{payLoading?"معالجة ..":"Pay with SkipCash"}</span>
                                      </button>
                                    }
                                    <button type = "button" className= "invoice-btn" onClick={printInvoice}>
                                        <span>
                                            <i style={{fontSize:"16px",marginLeft:"10px"}} className="fa-solid fa-print"></i>
                                        </span>
                                        <span>طباعة</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
};

export default Invoice;
