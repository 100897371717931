import React from 'react';
import image1 from '../../assets/images/last_work/1.jpeg'
import image2 from '../../assets/images/last_work/2.jpeg'
import image3 from '../../assets/images/last_work/3.jpeg'
import image4 from '../../assets/images/last_work/4.jpeg'
import image5 from '../../assets/images/last_work/5.jpeg'
import image6 from '../../assets/images/last_work/6.jpeg'
import image7 from '../../assets/images/last_work/7.jpeg'
import './our_works.css'

const OurWorks = () => {
    return (
        <div className="out-work">
            <div className="container">
                <div className="section-header">
                    <p>بعض اعمالنا السابقة</p>
                    <h2>اعمال سابقة</h2>
                </div>
                {/*<div className='slider-container left'>*/}
                {/*    <div className='img background-img'></div>*/}
                {/*    <div className='img foreground-img'></div>*/}
                {/*    <input type="range" min="1" max="100" value="50" className="slider" name='slider' id="slider" />*/}
                {/*        <div className='slider-button'></div>*/}
                {/*</div>*/}
                {/*<br/>*/}
                {/*<br/>*/}
            </div>
            <div className="cards">
                <div className="card">
                    <div className="out-work-item">
                        <img src={image1} alt="Service" />
                    </div>
                </div>
                <div className="card">
                    <div className="out-work-item">
                        <img src={image2} alt="Service" />
                    </div>
                </div>
                <div className="card">
                    <div className="out-work-item">
                        <img src={image3} alt="Service" />
                    </div>
                </div>
                <div className="card">
                    <div className="out-work-item">
                        <img src={image4} alt="Service" />
                    </div>
                </div>
                <div className="card">
                    <div className="out-work-item">
                        <img src={image5} alt="Service" />
                    </div>
                </div>
                <div className="card">
                    <div className="out-work-item">
                        <img src={image6} alt="Service" />
                    </div>
                </div>
                <div className="card">
                    <div className="out-work-item">
                        <img src={image7} alt="Service" />
                    </div>
                </div>
            </div>
            <div className="container">
                <a className="btn" href="src/pages">المزيد</a>
            </div>
        </div>
);
};

export default OurWorks;
