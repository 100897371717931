import React, {useEffect, useState} from "react";
import { MultiSelect } from "react-multi-select-component";
import "./select-multiple.css";

export default function SelectMultiple({optionsData,selectedWorkers, setSelectedWorkers}) {
    const options = [];
    optionsData.forEach((value)=>{
        options.push({ label: value.disabled? value.name + "* تم حجزها، اختر فترة او تاريخ اخر": value.name, value: value.value, disabled: value.disabled })
    })
    return (
        <div className="select-multiple-container">
            <MultiSelect
                options={options}
                value={selectedWorkers}
                onChange={setSelectedWorkers}
                overrideStrings={{
                    "allItemsAreSelected": "تم اختيار كل العناصر",
                    "clearSearch": "حذف البحث",
                    "clearSelected": "حذف الكل",
                    "noOptions": "لا خيار",
                    "search": "بحث",
                    "selectAll": "اختيار الكل",
                    "selectAllFiltered": "الختيار الكل (مفلتر)",
                    "selectSomeItems": "اختر عاملة...",
                    "create": "انشاء",
                }}
                disableSearch
                isCreatable={true}
             labelledBy={"select"}/>
        </div>
    );
}
