import React, {useEffect, useState} from 'react';
import "../login/login.css"
import BackButton from "../../back/back-button";
import {Link, useNavigate, useParams} from "react-router-dom";
import axios from "axios";
import {baseUrl} from "../../../common/const/consts";
import Loading from "../../loading/loading";
import Toast from "../../style/toast-notification/toast";
const ForgetPass = () => {
    const [showToast,setShowToast] = useState({show:false,message:""})
    const [codeFound,setCodeFound] = useState(false)
    const [registerLoading,setRegisterLoading] = useState(false)
    const [values,setValues] = useState({
        password:"",
        passwordConf:"",
    })
    const navigate = useNavigate()
    const {code} = useParams()
    const {email} = useParams()
    const checkCode = async () => {
        setRegisterLoading(true)
        const body = {email:email,code: code}
        console.log(JSON.stringify(body))
        await axios.post(`${baseUrl}login/send_email/`,body).then(res => {
            setRegisterLoading(false)
            console.log(res.data)
            if (res.status === 200){
                if (res.data.uid){
                    setValues({...values,["uid"]:res.data.uid,["regLogId"]:res.data.id})
                    setCodeFound(true)
                }
            }else {

            }
        })
    }
    useEffect(()=> {
        checkCode()
    },[])
    const saveData = async (e) => {
        e.preventDefault();
        console.log(values)
        if (values.passwordConf !== values.password){
            setShowToast({show: true,message: "تأكيد كلمة المرور غير متطابق"})
        }else {
            setRegisterLoading(true)
            const body = {table:"users",data:values}
            console.log(JSON.stringify(body))
            await axios.post(`${baseUrl}update/`,body).then(res => {
                setRegisterLoading(false)
                console.log(res.data)
                if (res.status === 200){
                    navigate("/login")
                }else {

                }
            })
        }
    }

    const onChange = (e) => {
        setValues({...values,[e.target.name]:e.target.value})
    }
    return (
        <div className="login_page">
            <BackButton />
            {registerLoading?
                <Loading />:
                codeFound?
                    <div className="container">
                        <div className="form-container sign-in-container">
                            <form onSubmit={saveData}>
                                <h2>تغيير كلمة المرور</h2>
                                <p>اكتب كلمة المرور الجديدة واعد تأكيدها</p>
                                <input onChange={onChange} value={values.password} name="password" type="password" placeholder="كلمة المرور الجديدة" required/>
                                <input onChange={onChange} value={values.passwordConf} name="passwordConf" type="password" placeholder="تأكيد كلمة المرور" required/>
                                <button
                                    className={registerLoading ? "primary-bg opacity-50" : "primary-bg"}
                                >{registerLoading ? "معالجة .." : "حفظ البيانات"}</button>
                            </form>
                        </div>
                    </div>:
                    <div className="expired">
                        <h4>
                            رابط منتهي الصلاحية
                        </h4>
                        <br/>
                        <Link to="/login"><button>رجوع للتسجيل</button></Link>
                    </div>
            }
            <Toast show={showToast} setShow={setShowToast}/>
        </div>
    );
};

export default ForgetPass;
