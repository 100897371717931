export const setFieldsValues = (values,setValues,services,nodesService,setNodesService,{data = services, serviceId = "0"}) => {
    const service = data.find(value => value.id === serviceId)
    let parentService = {}
    if(serviceId !== "0" && service){
        parentService = nodesService.find(value => value.id === service.parent_id)
    }
    let parentParentId = serviceId === "0"?serviceId:parentService.parent
    let parentId = serviceId === "0"?serviceId:parentService.id
    let list = [{
        id: "000233000",
        name: "date",
        type: "date",
        parent: "0",
        placeholder: `ادخل التاريخ`,
        errorMessage: `ادخل التاريخ`,
        label: "ما التاريخ المناسب معك",
        required: true
        // init_value: Date.now()
    }]
    data.filter((value)=> value.parent_id === serviceId).forEach(value => {
        let listValues = [{
            id:"0",
            name: `اختر ${value.title} ..`,
            value: ""
        }]
        if (value.type === "radio"){
            for (let i=0;i <= 1;i++){
                listValues.push({
                    id: value.id,
                    name: value.doc_id,
                    value: i
                })
            }
        }else {
            data.filter((val)=> val.parent_id === value.id).forEach(nodesVal => {
                listValues.push({
                    id:nodesVal.id,
                    name: nodesVal.title,
                    value: nodesVal.id
                })
            })
        }
        list.push({
            id: value.id,
            name:  value.doc_id,
            type: value.type,
            parent: parentId,
            placeholder: ` ${value.title}`,
            errorMessage: `ادخل ${value.title}`,
            label: value.title,
            values: listValues,
            init_value: value.init_value,
            required: true,
            categid: value.categ_id
        })
    })
    let tempNode = []
    if (parentParentId === "0"){
        tempNode = [...nodesService.filter(value => value.parent === parentParentId)]
    }else {
        tempNode = [...nodesService.filter(value => value.parent !== parentId)]
    }
    list.forEach(value => {
        if (tempNode.filter(value1 => value1.id === value.id).length === 0){
            tempNode.push(value)
        }
    })
    setNodesService(tempNode);
    // let valuesTemp = {}
    // tempNode.forEach((val)=> {
    //     valuesTemp[val.name] = ""
    // })
    // setValues({orderData: valuesTemp});
}
