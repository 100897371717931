
export const baseUrlLocal = 'https://alaman-qa.com/';

// export const paymentUrl = 'https://alaman-qa.com/back2/app/api/skipcash/create_payment/';
export const paymentUrl = 'https://alaman-qa.com/backend/app/api/skipcash/create_payment/';

export  const  baseUrl = "https://alaman-qa.com/backend/app/api/"
// export  const  baseUrl = "https://alaman-qa.com/back2/app/api/"

export  const  workersFieldId = "4444"
