import React, {useEffect, useState} from 'react';
import BackButton from "../../back/back-button";
import { useNavigate, useParams} from "react-router-dom";
import axios from "axios";
import {baseUrl} from "../../../common/const/consts";
import {ApiFunctions} from "../../../common/api_funcotions/api_functions";
import Toast from "../../style/toast-notification/toast";
import Skeleton from "../../skeleton/skeleton";
import "./profile.css"
const Profile = () => {
    const [showToast,setShowToast] = useState({show:false,message:""})
    const apiFunction = new ApiFunctions()
    const [registerLoading,setRegisterLoading] = useState(false)
    const [values,setValues] = useState({
        uid:"",
        name:"",
        phone:"",
        email:""
    })
    const navigate = useNavigate()
    const checkCode = async () => {
        setRegisterLoading(true)
        const body = {table:"users",field: "uid",value: apiFunction.getUserId()}
        console.log(JSON.stringify(body))
        await axios.post(`${baseUrl}select_by/`,body).then(res => {
            setRegisterLoading(false)
            if (res.status === 200){
                if (res.data.user){
                    setValues(res.data[0])
                }else {
                    logout()
                }
            }else {

            }
        })
    }
    useEffect(()=> {
        checkCode()
    },[])
    const saveData = async (e) => {
        e.preventDefault();
        console.log(values)
        if (values.passwordConf !== values.password){
            setShowToast({show: true,message: "خطأ في الايميل او كلمة المرور"})
        }else {
            setRegisterLoading(true)
            const body = {table:"users",data:values}
            console.log(JSON.stringify(body))
            await axios.post(`${baseUrl}update/`,body).then(res => {
                setRegisterLoading(false)
                console.log(res.data.user)
                if (res.status === 200){
                    apiFunction.setCUserId(values.uid)
                }else {
                    apiFunction.setCUserId("")
                }
            })
        }
    }

    const onChange = (e) => {
        setValues({...values,[e.target.name]:e.target.value})
    }
    const logout = () => {
        apiFunction.setCUserId("")
        navigate("/")
    }
    const lastOrders = (e) => {
        navigate("/last_orders")
    }

    return (
        <div className="profile">
            <BackButton title="الملف الشخصي"/>
            <div className="profile-container">
            <button className="last-btn" onClick={lastOrders}>الفواتير السابقة</button>
                {registerLoading?
                  <Skeleton length={4}/>:
                  <div>
                    <div className="fontuser">
                        <label><b>رقم الحساب</b></label>
                        <input style={{fontFamily: "shamel"}} disabled value={values.uid} onChange={onChange}
                               type="text"
                               placeholder="رقم الحساب"
                               name="uid" required/>
                        <i className="fa-solid fa-hashtag"></i>
                    </div>
                    <div className="fontuser">
                        <label><b>الايميل</b></label>
                        <input disabled value={values.email} onChange={onChange} type="email"
                               placeholder="الايميل"
                               name="email" required/>
                        <i className="fa-solid fa-envelope"></i>
                    </div>
                    <div className="fontuser">
                        <label><b>الاسم</b></label>
                        <input value={values.name} onChange={onChange} type="text"
                               placeholder="الاسم"
                               name="name" required/>
                        <i className="fa-solid fa-user"></i>
                    </div>
                    <div className="fontuser">
                        <label><b>رقم الهاتف</b></label>
                        <input value={values.phone} onChange={onChange} type="number"
                               placeholder="رقم الهاتف"
                               name="phone" required/>
                        <i className="fa-solid fa-phone"></i>
                    </div>
                    <button onClick={logout}>تسجيل خروج</button>
                  </div>
                }
            </div>
            <Toast show={showToast} setShow={setShowToast} />
        </div>
    );
};

export default Profile;
