import React from 'react';
import villa from '../../assets/images/services/builders/villa.jpeg'
import government from '../../assets/images/services/builders/government.jpeg'
import school from '../../assets/images/services/builders/school.webp'
import restaurants from '../../assets/images/services/builders/restaurants.webp'
import hotels from '../../assets/images/services/builders/hotels.jpeg'
import hospitals from '../../assets/images/services/builders/hospitals.jpeg'
import './services.css'
function Services() {
    const services = [
        {
            image: <img className="icon"
                        src={villa}
                        alt="icon" />,
            name:"الفلل و القصور",
            details:"خدمات نظافة شاملة للفلل والقصور وبجودة عالية"
        },
        {
            image: <img className="icon"
                        src={government}
                        alt="icon" />,
            name:"الفلل و القصور",
            details:"خدمات نظافة شاملة للفلل والقصور وبجودة عالية"
        },
        {
            image: <img className="icon"
                        src={school}
                        alt="icon" />,
            name:"المدارس والمعاهد",
            details:"نملك خبرة طويلة في التعامل مع المؤسسات التعليمية"
        },
        {
            image: <img className="icon"
                        src={restaurants}
                        alt="icon" />,
            name:"المطاعم",
            details:"نجعل مطعمك مستوفي لأعلى معايير النظافة طول فترة التعاقد"
        },
        {
            image: <img className="icon"
                        src={hotels}
                        alt="icon" />,
            name:"الفنادق",
            details:"نظافة الفنادق تحتاج الى عمالة متخصصة ومدربة بمعايير عالية وهذا ما نوفره بالضبط"
        },
        {
            image: <img className="icon"
                        src={hospitals}
                        alt="icon" />,
            name:"المستشفيات",
            details:"تلتزم المستشفيات معايير صارمة للنظافة، ونحن بدورنا نساعدك في الاتزام بها"
        },
    ]
    return (
        <div>
            <div id="services" className="service">
                <div className="container">
                    <div className="section-header">
                        <p>نقدم خدماتنا</p>
                        <h2>لمختلف المرافق</h2>
                    </div>
                </div>
                <div className="cards">
                   {services.map((value,index)=> (
                        <div key={index} className="card">
                            <div className="service-item">
                                {value.image}
                                <h4>{value.name}</h4>
                                <p>
                                    {value.details}
                                </p>
                            </div>
                        </div>
                   ))}
                </div>
                <div className="container">
                    <a className="btn" href="src/pages">المزيد</a>
                </div>
            </div>
        </div>
    );
}

export default Services;
