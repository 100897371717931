import React, {useEffect, useState} from 'react';
import "./last_orders.css"
import BackButton from "../../../back/back-button";
import axios from "axios";
import {baseUrl} from "../../../../common/const/consts";
import {useNavigate, useParams} from "react-router-dom";
import {ApiFunctions} from "../../../../common/api_funcotions/api_functions";
import Loading from "../../../loading/loading";

const LastOrders = () => {
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState([])
    const apiFunction = new ApiFunctions()
    const navigate = useNavigate()
    const toInvoice = (id) => {
        navigate("/invoice/"+id)
    }
    useEffect(() => {
        getData()
    }, [])
    const getData = () => {
        setLoading(true)
        const body = {userId: apiFunction.getUserId()}
        console.log(JSON.stringify(body))
        axios.post(`${baseUrl}select/orders/`, body).then(value => {
            console.log(value.data)
            setData(value.data)
            setLoading(false)
        })
    }
    return (
        <div className="last-container">
            <BackButton title="الفواتير"/>
            <br/>
            {loading?
                <Loading />
                :data.length > 0?<table className="table">
                <thead>
                <tr>
                    <th>رقم الفاتورة</th>
                    <th>التاريخ</th>
                    <th>الاجمالي</th>
                    <th>نوع الدفع</th>
                    <th>حالة الدفع</th>
                </tr>
                </thead>
                <tbody>
                {data.map((value, index) =>
                    <tr onClick={() => toInvoice(value.doc_id)} key={index}>
                        <td data-label="رقم الفاتورة">{value.doc_id}</td>
                        <td data-label="التاريخ">{value.created_at}</td>
                        <td data-label="الاجمالي">{value.total}</td>
                        <td data-label="نوع الدفع">
                            <div className={value.payment_type === 2 ? "status-badge green" : "status-badge orange"}>
                                {value.payment_type === 2 ? "الكتروني" : "كاش"}
                            </div>
                        </td>
                        <td data-label="حالة الدفع">
                            <div className={value.payment_status === 2 ? "status-badge green" : "status-badge orange"}>
                                {value.payment_status === 2 ? "تم" : "لم يتم"}
                            </div>
                        </td>
                    </tr>
                )}
                </tbody>
            </table>:<div className="mt-5 pr-5">لا توجد فواتير</div>}
        </div>
    );
};

export default LastOrders;
