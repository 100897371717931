import React, {useState} from 'react';
import "./login.css"
import {Link, useNavigate} from "react-router-dom";
import axios from "axios";
import {baseUrl, baseUrlLocal} from "../../../common/const/consts";
import BackButton from "../../back/back-button";
import Toast from "../../style/toast-notification/toast";
import {ApiFunctions} from "../../../common/api_funcotions/api_functions";
const Login = () => {
    const apiFunction = new ApiFunctions()
    const [showToast,setShowToast] = useState({show:false,message:""})
    const [register,setRegister] = useState(false)
    const [forget,setForget] = useState(false)
    const [registerLoading,setRegisterLoading] = useState(false)
    const [values,setValues] = useState({
        phone:"",
        password:""
    })
    const navigate = useNavigate()
    const registerEmail = async (e) => {
        e.preventDefault();
        setRegisterLoading(true)
        // let body = {email:values.email,password:values.password}
        let body = {phone:values.phone,password:values.password}
        let url = `${baseUrl}auth/`
        if (register){
             body = {insert:true,email:values.email,url:baseUrlLocal,path:"registration"}
             url = `${baseUrl}login/send_email/`
            if (forget){
                body = {insert:false,email:values.email,url:baseUrlLocal,path:"forget_password"}
            }
        }
        console.log(JSON.stringify(body))
       await axios.post(url,body).then(res => {
           setRegisterLoading(false)
           console.log(res.data)
           console.log(res.data.found)
           if (res.status === 200){
               if (register){
                   if (res.data.found || res.data === true){
                       navigate("/order_sent/1")
                   }else {
                       setShowToast({show: true,message: res.data.msg})
                   }
               }else {
                   if (res.data.msg === "success"){
                       apiFunction.setCUserId(res.data.user.uid)
                       navigate("/")
                   }else {
                       console.log("login in filed")
                       setShowToast({show: true,message: res.data.msg})
                   }
               }
           }else {

           }
        })
    }
    const onChange = (e) => {
        setValues({...values,[e.target.name]:e.target.value})
    }
    const changeToForget = () => {
        setForget(true)
        setRegister(!register)
    }
    const changeToRegister = () => {
        setForget(false)
        setRegister(!register)
    }
    return (
        <div className="login_page">
            <BackButton title=""/>
            <div className="container">
                <div className="form">
                    <form onSubmit={registerEmail}>
                        <h2>{register?forget?"استعادة الحساب":"انشاء حساب":"تسجيل دخول"}</h2>
                        {register && <p>{forget?"ادخل ايميلك لارسال رابط استعادة كلمة المرور عليه":"سيتم ارسال رابط تحقق على الايميل"}</p>}
                        <input onChange={onChange} name="phone"    value={values.phone} type="number" placeholder="رقم الهاتف" required/>
                        {register?null:
                            <input onChange={onChange} name="password" value={values.password} type="password" placeholder="كلمة المرور" required/>}
                        {!register && <Link onClick={changeToForget} to="">نسيت كلمة المرور؟</Link>}
                        {register && <br/>}
                        <br/>
                        <button
                            className={register?registerLoading?"accent-bg opacity-50":
                                "accent-bg":registerLoading?"primary-bg opacity-50":"primary-bg"}
                        >{registerLoading?"معالجة ..":register?forget?"ارسال":"انشاء حساب":"تسجيل دخول"}</button>
                        <br/>
                    </form>
                    <br/>
                    <button onClick={changeToRegister}>{register?"لدي حساب":"حساب جديد"}</button>
                </div>
            </div>
            <Toast show={showToast} setShow={setShowToast} />
        </div>
    );
};

export default Login;
