import React from 'react';
import backIcon from "../../assets/icons/back.svg";
import {useNavigate} from "react-router-dom";

const BackButton = ({title = "انشاء طلب"}) => {
    const navigate = useNavigate()
    const back = () => {
        navigate(-1)
    }
    return (
        <div className="row-really">
            <img className="mt-1 mr-1 icon" onClick={back} src={backIcon} alt="back" />
            <h4 className="mr-1 pt-1">{title}</h4>
        </div>
    );
};

export default BackButton;
