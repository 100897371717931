import {setFieldsValues} from "./setFieldsValues";

export const onChange = (
    user,
    values,
    setValues,
    totalPrice,
    setTotalPrice,
    services,
    nodesService,
    setNodesService,
    setFieldsIsValid,
    apiFunction,
    e
) => {
    const serviceName = services.find(value => value.doc_id === e.target.name)
    if (e.target.value !== "" && serviceName){
        const serviceValue = services.find(value => value.id === e.target.value)
        if (serviceName?.type === "select"){
            // console.log(serviceName)
            setFieldsValues(values,setValues,services,nodesService,setNodesService,{serviceId:e.target.value})
            let total = totalPrice.total + +serviceValue?.price? +serviceValue.price : totalPrice.total
            if (totalPrice.items.filter(value1 => value1 === e.target.name).length === 0){
                total = +serviceValue?.price? +serviceValue.price: totalPrice.total
            }
            setTotalPrice({total: total, items: [...totalPrice.items,{...serviceValue?.parent_id?serviceValue?.parent_id:""}]})
        }else if (serviceName.type === "range" || serviceName.type === "number"){
            let total = (+serviceName.price * +e.target.value) + totalPrice.total
            //if the filed hav`t price
            if (+serviceName.price === 0){
                total = +e.target.value * totalPrice.total
            }
            let items = [...totalPrice.items]
            items.push(serviceName.doc_id)
            if (totalPrice.items.filter(value1 => value1 === e.target.name).length !== 0){
                total = totalPrice.total - (+serviceName.price * values.orderData[e.target.name])
                total = (+serviceName.price * +e.target.value) + total
                //if the filed hav`t price
                if (+serviceName.price === 0){
                    total = +totalPrice.total / values.orderData[e.target.name]
                    total = +total * +e.target.value
                }
            }
            setTotalPrice({total: total, items: items})
        }else if (serviceName.type === "radio"){
            let total = totalPrice.total
            let items = [...totalPrice.items]
            items.push(serviceName.doc_id)
            if (+e.target.value === 0){
                if (totalPrice.items.filter(value1 => value1 === e.target.name).length !== 0){
                    total = totalPrice.total - +serviceName.price
                }
            }else {
                total = totalPrice.total + +serviceName.price
            }
            setTotalPrice({total: total, items: items})
        }
        setValuesFun(nodesService,e,values,setValues,e.target.value)
    }else {
        if (serviceName){
            let total = totalPrice.total - (+serviceName.price * values.orderData[e.target.name])
            if (total > 0){
                setTotalPrice({total: total, items: []})
            }else {
                setTotalPrice({total: 0, items: []})
            }
            setValuesFun(nodesService,e,values,setValues,"")
        }else {
            if (user){
                if (e.target.name === "city"){
                    let cityField = nodesService.find((value)=> value.name === e.target.name)
                    let cityValue = cityField.values.find((value)=> value.id === +e.target.value)
                    let cityValueLast = cityField.values.find((value)=> value.id === +values.user[e.target.name])

                    if (cityValue?.price){
                    let newTotal = totalPrice.total + cityValue?.price
                        if (cityValueLast?.price){
                            newTotal = newTotal - cityValueLast.price
                        }
                        setTotalPrice({total: newTotal, items: totalPrice.items})
                    }else {
                        if (cityValueLast?.price){
                            let newTotal = totalPrice.total - cityValueLast.price
                            setTotalPrice({total: newTotal, items: totalPrice.items})
                        }
                    }
                }
                setValues({
                    user: {...values.user,
                        [e.target.name] : e.target.value,
                        user_id : apiFunction.getUserId(),
                        total : totalPrice.total
                    },
                    orderData:values.orderData
                });
            }else {
                setValuesFun(nodesService,e,values,setValues,e.target.value)
            }
        }
    }

    if(totalPrice.total > 0){
        setFieldsIsValid(true)
    }else {
        setFieldsIsValid(false)
    }
}


function setValuesFun(nodesService,e,values,setValues,value){
    let noeObject = nodesService.find(value => value.name === e.target.name)
    if (noeObject.parent === "0" && noeObject.name !== "date"){
        let orderData = {...values.orderData}
        nodesService.forEach(val => {
            if (val.parent !== "0"){
                orderData = {...orderData,[val.name]:""}
            }
        })
        setValues({user: values.user,
            orderData: {
                ...orderData,
                [e.target.name]:value
            }
        });
    }else {
        setValues({user: values.user,
            orderData: {
                ...values.orderData,
                [e.target.name]:value
            }
        });
    }
}
