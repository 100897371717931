import React, {useState} from 'react';
import "./inner-navbar.css"
import {Link, useLocation} from "react-router-dom";
import logo from "../../../assets/images/header/alamanlogo.png";
import ContactIcons from "../../header/contact-icons";
const InnerNavbar = () => {
    const [white,setWhite] = useState(true)
    const location = useLocation();
    console.log(location.pathname);
    window.onscroll = function() {
        let navbar
        let logo
        if (document.getElementById("nav")){
            navbar = document.getElementById("nav")
            logo = document.getElementById("logo")
        }
        if(window.scrollY === 0 && document.getElementById("nav")){
            navbar.style.backgroundColor = "transparent"
            // ul.style.backgroundColor = "transparent"
            navbar.style.boxShadow = "none"
            setWhite(true)

            logo.style.setProperty("--brandBg","white")

            logo.style.setProperty("--scaleLogo","0")
            logo.style.setProperty("--brandMoving","0")

            logo.style.setProperty("--brandScaleMobile","0")
            logo.style.setProperty("--brandMovingMobile","0")

        } else if (document.getElementById("nav")) {
            navbar.style.backgroundColor = "#fff"
            navbar.style.boxShadow = "1px 1px 4px 0 rgba(0,0,0,.1)"
            setWhite(false)

            logo.style.setProperty("--scaleLogo",".34")
            logo.style.setProperty("--brandBg","transparent")
            logo.style.setProperty("--brandMoving","-5rem")

            logo.style.setProperty("--brandScaleMobile",".34")
            logo.style.setProperty("--brandMovingMobile","-5.2rem")
        }
    }

    const profileLink = "https://protocolqa.com/profiles/%D8%A8%D8%B1%D9%88%D8%AA%D9%88%D9%83%D9%88%D9%84%20%D9%84%D9%84%D8%A7%D8%B3%D8%AA%D8%B4%D8%A7%D8%B1%D8%A7%D8%AA%20%D9%88%D8%A7%D9%84%D8%AA%D8%AF%D8%B1%D9%8A%D8%A8.pdf"
    return (
        <header id="nav" className="inner-navbar">
            <div id="logo" className="brand">
                <img src={logo} alt="Logo" />
            </div>
            <ContactIcons white={white}/>
        </header>
    );
};

export default InnerNavbar;
