import React, {useEffect, useRef, useState} from 'react';
import FormInput from "../../components/form/form_input";
import "./booking.css"
import {ApiFunctions} from "../../common/api_funcotions/api_functions";
import {Link, useNavigate} from "react-router-dom";
import {setUserOrder} from "./functions/setOrderUser";
import {setFieldsValues} from "./functions/setFieldsValues";
import {onChange} from "./functions/onChange";
import Skeleton from "../../components/skeleton/skeleton";
import Toast from "../../components/style/toast-notification/toast";
import axios from "axios";
import {baseUrl, workersFieldId} from "../../common/const/consts";
import backIcon from "../../assets/icons/back.svg";
import StepsForm from "../steps_form/step_form";

const Booking = () => {
    const apiFunction = new ApiFunctions()
    const navigate = useNavigate()
    const form = useRef()

    const [values, setValues] = useState({
        user:{user_id:""},
        orderData:{}
    });
    const [getWorkersLoading, setGetWorkersLoading] = useState(false);
    const [readyWorkers, setReadyWorkers] = useState([{
        id:"0",
        name: 'اختر عاملات ..',
        value: ""
    }]);
    const [selectedWorkers, setSelectedWorkers] = useState([]);

    const [loading, setLoading] = useState(false)
    const [loadingService, setLoadingService] = useState(false)
    const [fieldsIsValid, setFieldsIsValid] = useState(false)
    const [totalPrice,setTotalPrice] = useState({total:0,items:[]})

    const [currentStep,setCurrentStep] = useState(1)
    const [services,setServices] = useState([])
    const [nodesService,setNodesService] = useState([])
    const [showToast,setShowToast] = useState({show:false,message:""})

    useEffect(()=> {
        getData()
    },[])

    const getData = () => {
        setLoadingService(true)
        let promise = apiFunction.getServices();
        promise
            .then((res) => {
                setLoadingService(false)
                setServices(res)
                setFieldsValues(values,setValues,services,nodesService,setNodesService,{data:res})
            })
            .catch(() => {
                setLoadingService(false)
                console.log('Some error has occurred');
            });
    }
    const [user,setUser] = useState(false)
    const handleSubmit = (e) => {
        e.preventDefault();
        if (currentStep === 1){
            if (1 === 1){
                // if (apiFunction.loggedIn()){
                if (totalPrice.total > 0){
                    setUserOrder(values,setValues,setUser,setNodesService)
                    setUser(true)
                    setCurrentStep(2)
                }else {
                    setShowToast({show: true,message: "اختر احد الخيارات"})
                }
            }else {
                navigate("/login")
            }
        }else if (currentStep === 2){
            if (fieldsIsValid){
                let workers = []
                selectedWorkers.forEach((value)=>{
                    workers.push(value.value)
                })
                values.orderData["1699001861143"] = workers.toString()
                values.user.total = getCalTotal();
                console.log(JSON.stringify(values))
                console.log(values)
                setLoading(true)
                apiFunction.saveData(values).then(data => {
                    console.log(data);
                    setLoading(false)
                    navigate(`/order_sent/2/${data.orderId}/${totalPrice.total}/${values.user.payment_type}`)
                });
            }else {
                setShowToast({show: true,message: "اكمل جميع الحقول"})
            }
        }
    }
    const onchangeField = (e) => {
        onChange(
            user,
            values,
            setValues,
            totalPrice,
            setTotalPrice,
            services,
            nodesService,
            setNodesService,
            setFieldsIsValid,
            apiFunction,
            e
        )
        //check workers available
        const serviceName = services.find(value => value.id === e.target.value)
        // console.log(serviceName?.categ_id)
        if (serviceName?.categ_id === "5555"){
            getReadyWorkers({range_time:e.target.value})
        }else if (e.target.name === "date" && values.orderData["1695467705755"]){
            getReadyWorkers({date:e.target.value})
        }
    }

    const back = () => {
        setNodesService([])
        if (currentStep === 2){
            getData()
            setCurrentStep(1)
            setUser(false)
        }else if (currentStep === 3){
            setUserOrder(values,setValues,setUser,setNodesService)
            setCurrentStep(2)
        }
    }

    const getReadyWorkers = ({date = values.orderData.date, range_time = values.orderData["1695467705755"]}) => {
        setGetWorkersLoading(true)
        const body = {booked_date: date, range_time: range_time}
        console.log(JSON.stringify(body))
        axios.post(`${baseUrl}select/orders/orders_workers/`,body).then(res=>{
            setGetWorkersLoading(false)
            if (res.data.found){
                setReadyWorkers(res.data.readyWorker)
            }
            console.log(res.data)
        })
    }
    const checkWorkerField = (categid) => {
        return categid === workersFieldId
    }
    const getCalTotal = () => {
        if (selectedWorkers?.length){
            return totalPrice.total*selectedWorkers?.length
        }else {
            return totalPrice.total
        }
    }

    return (
        <div className="App">
            <div className="container-form">
                <div className="title">
                    {currentStep > 1?<img onClick={back} src={backIcon} alt="back" />:
                        <Link to="/"><img className="pt-1" onClick={back} src={backIcon} alt="back" /></Link>}
                    <div className="row-really">
                        <h3>انشاء طلب</h3>
                        <h4>/ {currentStep === 1?"بيانات الطلب":"بيانات العميل"}</h4>
                    </div>
                </div>
                {/*<StepsForm current={currentStep}/>*/}
                <form ref={form} onSubmit={handleSubmit}>
                    <div className="form-content">
                        {loadingService?
                            <Skeleton />:
                            nodesService.map((value) => (
                                <div
                                    key={value.id}
                                >
                                    <FormInput
                                        {...value}
                                        onChange={onchangeField}
                                        value={user ? values.user[value.name] ? values.user[value.name] : "" :
                                            values.orderData[value.name] ? values.orderData[value.name] : ""}
                                        valuesTitle={value.valuesTitle}
                                        readyWorkers={readyWorkers}
                                        init_value={value.init_value}
                                        checkWorkerField={checkWorkerField}
                                        getWorkersLoading={getWorkersLoading}
                                        dateValue={values.orderData.date}
                                        selectedWorkers={selectedWorkers}
                                        setSelectedWorkers={setSelectedWorkers}
                                    />
                                </div>
                            ))}
                    </div>
                    <div className="footer-form row">
                        <div className="total-form">
                            <h5>الاجمالي</h5>
                            <div className="row">
                                <h4>{getCalTotal()}</h4>
                                <h5>QAR</h5>
                            </div>
                        </div>
                        <button
                            className={loading?"loading-btn":totalPrice.total > 0 && readyWorkers?"":
                                "loading-btn"}>{loading?'معالجة ..':
                            currentStep === 3?'ارسال':'التالي'}</button>
                    </div>
                    {/*<button>اضغط للحفظ</button>*/}
                </form>
                {currentStep === 3 &&
                    <div className="payment">
                        صحفة الدفع
                    </div>}
                <Toast show={showToast} setShow={setShowToast}/>
                {/*<button onClick={setCitiesValues}>set data</button>*/}
            </div>
        </div>
    );
}
export default Booking;
