import {useState} from "react";

export const RadioGroup = ({ children }) => {
    return (
        <div
            role="radiogroup"
            className="radio-group"
            aria-labelledby="group_heading"
        >
            {children}
        </div>
    );
};

export const Radio = ({
                          children,
                          id,
                          name,
                          onChange,
                          value
                      }) => {

    const [focused, setFocused] = useState(false);
    const handleFocus = (e) => {
        setFocused(true);
    };

    return (
        <>
            <input
                value={value}
                onChange={onChange}
                type="radio"
                id={id}
                name={name}
                onBlur={handleFocus}
                focused={focused.toString()}
                required
            />
            <label className="radio-label" htmlFor={id}>
                {children}
            </label>
        </>
    );
};
