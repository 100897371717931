import React from 'react';
import constructionWorker from '../../../assets/images/services/details/construction-worker.png'
import sofa from '../../../assets/images/services/details/sofa.png'
import carpet from '../../../assets/images/services/details/carpet.png'
import armchair from '../../../assets/images/services/details/armchair.png'
import window from '../../../assets/images/services/details/window.png'
import mall from '../../../assets/images/services/details/mall.png'
import kitchen from '../../../assets/images/services/details/kitchen.png'
// import tiles from '../../../assets/images/services/details/tiles.png'
// import disinfection from '../../../assets/images/services/details/disinfection.png'
// import waterTank from '../../../assets/images/services/details/water-tank.png'
// import pestControl from '../../../assets/images/services/details/pest-control.png'
// import villa from "../../../assets/images/services/builders/villa.jpeg";
// import government from "../../../assets/images/services/builders/government.jpeg";
// import school from "../../../assets/images/services/builders/school.webp";
// import restaurants from "../../../assets/images/services/builders/restaurants.webp";
// import hotels from "../../../assets/images/services/builders/hotels.jpeg";
// import hospitals from "../../../assets/images/services/builders/hospitals.jpeg";
import './details.css'

const list = [
    {
        image:constructionWorker,
        title:"عاملات و عمال تنظيف",
        details:"توفر شركتنا عاملات وعمال (للتنظيف العام ) بنظام الساعات أو بنظام الشهري ،\n" +
            "                                حيث نقوم بتوظيف العامل ومساعدته على فهم وتلبيه متطلبات العميل"
    },
    {
        image:sofa,
        title:"تنظيف الأثاث",
        details:"\n" +
            "                                            نقوم بإزالة الأتربة بإستخدام معدات آمنة وفعالة في إزالة أصعب البقع التي\n" +
            "                                            تستقر في الاماكن الضيقة والتي يصعب الوصول إليها"
    },
    {
        image:carpet,
        title:"تنظيف السجاد و الموكيت",
        details:"سيتمكن فريقنا المحترف بتنظيف السجاد والموكيت ثم تجفيفه للتخلص من الروائح\n" +
            "                                            الكريهة وتغيير مظهرها لتعود منعشة ونظيفة\n" +
            "                                       "
    },
    {
        image:armchair,
        title:"تنظيف الكنب و إزالة البقع",
        details:"\n" +
            "                                            تستخدم شركتنا أحدث الأجهزة للتنظيف بالبخار وذلك لقدرته على تنظيف أصعب البقع\n" +
            "                                            ، وبمواد صالحة وآمنة على الرغم من أختلاف الأقمشة وتنوعها"
    },
    {
        image:window,
        title:"تنظيف المراتب و الستائر",
        details:"نستخدم معدات وأجهزة حديثة مصممة خصيصا لتنظيف المراتب و الستائر وضبطها على\n" +
            "                                            برنامج سريع دون عصر أو تلف"
    },
    {
        image:mall,
        title:"تنظيف المساحات الخارجية",
        details:"مستعدون لغسل وتنظيف جميع المساحات الخاصة – المولات\n" +
            "                                            المستشفيات – المرافق الخدمية) بمختلف أشكالها ومساحتها"
    },
    {
        image:window,
        title:"تنظيف المراتب و الستائر",
        details:"فريقنا المختص يستخدم العديد من المواد الفعالة والأدوات الحديثة التي تستطيع\n" +
            "                                            الوصول إلى الأماكن الضيقة أو المرتفعة حتى تصبح النوافذ والواجهات لامعة\n" +
            "                                            ونظيفة ولا تحجب الرؤية"
    },
    {
        image: kitchen,
        title:"تنظيف مطابخ",
        details:"نقوم بتنظيف الأدوات والمعدات مثل فرن الغاز والفرن الكهربائي والثلاجة وغسالة\n" +
            "                                            الأطباق . بواسطة عاملات مدربات ومؤهلات لهذا النوع من الاعمال"
    },
]
function Details() {
    return (
        <div>
            <div className="details">
                <div className="container">
                    <div className="section-header">
                        <p>خدماتنا</p>
                        <h2>جميع الخدمات</h2>
                    </div>
                </div>
                <div className="cards">
                    {list.map((value,index) => (
                        <div key={index} className="card-details">
                            <div className="details-item-details">
                                <img src={value.image} alt="Service" />
                                <h4>{value.title}</h4>
                                <p>{value.details}</p>
                            </div>
                        </div>
                        )
                    )}
                </div>
                <div className="container">
                    <a className="btn" href="src/pages">المزيد</a>
                </div>
            </div>
            {/*<div>*/}
            {/*    <div className="service">*/}
            {/*        <div className="container">*/}
            {/*            <div className="section-header">*/}
            {/*                <p>خدماتنا</p>*/}
            {/*                <h2 className="a-color">جميع الخدمات</h2>*/}
            {/*            </div>*/}
            {/*            <div className="row">*/}
            {/*                <div className="col-8 col-md-6">*/}
            {/*                    <div className="row align-items-center feature-item">*/}
            {/*                        <img className="icon-service" src={kitchen} alt="Feature" />*/}
            {/*                        <div className="col-md-6">*/}
            {/*                            <h5 className="primary-color bold-text">تنظيف مطابخ</h5>*/}
            {/*                            <p>*/}
            {/*                                نقوم بتنظيف الأدوات والمعدات مثل فرن الغاز والفرن الكهربائي والثلاجة وغسالة*/}
            {/*                                الأطباق . بواسطة عاملات مدربات ومؤهلات لهذا النوع من الاعمال*/}
            {/*                            </p>*/}
            {/*                        </div>*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*                /!*<div className="col-8 col-md-6">*!/*/}
            {/*                /!*    <div className="row align-items-center feature-item">*!/*/}
            {/*                /!*        <img className="icon-service" src={tiles} alt="Feature" />*!/*/}
            {/*                /!*        <div className="col-md-6">*!/*/}
            {/*                /!*            <h5 className="primary-color bold-text">تلميع الرخام و السيراميك</h5>*!/*/}
            {/*                /!*            <p>*!/*/}
            {/*                /!*                إزالة أصعب الخدوش من الرخام عن طريق الصقل ثم يتم تلميعه ليعود بريقه ولمعانه*!/*/}
            {/*                /!*                ، كما نقوم بمعالجة الخطوط السوداء من على السيراميك بمواد خاصة وفعالة*!/*/}
            {/*                /!*            </p>*!/*/}
            {/*                /!*        </div>*!/*/}
            {/*                /!*    </div>*!/*/}
            {/*                /!*</div>*!/*/}
            {/*                /!*<div className="col-8 col-md-6">*!/*/}
            {/*                /!*    <div className="row align-items-center feature-item">*!/*/}
            {/*                /!*        <img className="icon-service" src={disinfection} alt="Feature" />*!/*/}
            {/*                /!*        <div className="col-md-6">*!/*/}
            {/*                /!*            <h5 className="primary-color bold-text">التعقيم و التطهير</h5>*!/*/}
            {/*                /!*            <p>*!/*/}
            {/*                /!*                نقوم بتعقيم جميع الأسطح وكل ما يمكن لمسه ، وتنظيف الأرضيات بمختلف مساحاتها*!/*/}
            {/*                /!*                من الأتربة الضارة*!/*/}
            {/*                /!*            </p>*!/*/}
            {/*                /!*        </div>*!/*/}
            {/*                /!*    </div>*!/*/}
            {/*                /!*</div>*!/*/}
            {/*                /!*<div className="col-8 col-md-6">*!/*/}
            {/*                /!*    <div className="row align-items-center feature-item">*!/*/}
            {/*                /!*        <img className="icon-service" src={waterTank} alt="Feature" />*!/*/}
            {/*                /!*        <div className="col-md-6">*!/*/}
            {/*                /!*            <h5 className="primary-color bold-text">تنظيف خزانات المياه</h5>*!/*/}
            {/*                /!*            <p>*!/*/}
            {/*                /!*                نعمل على إزالة الأوساخ المتراكمة والطحالب والصدأ الذي يكون سبب في تأكل*!/*/}
            {/*                /!*                الخزانات والتقليل من صلاحية عمرها الافتراضي*!/*/}
            {/*                /!*            </p>*!/*/}
            {/*                /!*        </div>*!/*/}
            {/*                /!*    </div>*!/*/}
            {/*                /!*</div>*!/*/}
            {/*                /!*<div className="col-8 col-md-6">*!/*/}
            {/*                /!*    <div className="row align-items-center feature-item">*!/*/}
            {/*                /!*        <img className="icon-service" src={pestControl} alt="Feature" />*!/*/}
            {/*                /!*        <div className="col-md-6">*!/*/}
            {/*                /!*            <h5 className="primary-color bold-text">رش المبيدات</h5>*!/*/}
            {/*                /!*            <p>*!/*/}
            {/*                /!*                مكافحة مختلف أنواع الحشرات والآفات ، برشها بمبيدات فعالة توافق أعلى معاير*!/*/}
            {/*                /!*                الجودة وآمنة تماما على صحة الإنسان*!/*/}
            {/*                /!*            </p>*!/*/}
            {/*                /!*        </div>*!/*/}
            {/*                /!*    </div>*!/*/}
            {/*                /!*</div>*!/*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </div>
    );
}

export default Details;
