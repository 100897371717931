import React from 'react';
import aboutImage from '../../assets/images/aboute/about_us.png'
import './aboute.css'
function AboutUs() {
    return (
        <div>
            <div id="aboute" className="about">
                <div className="container">
                    <div className="row">
                        <img src={aboutImage} alt="Image"/>
                        <div className="about-text">
                            <h2>عن الشركة</h2>
                            <p>
                                شركة رائدة تقدم خدمات مميزة في أعمال التنظيف والتطهير والتعقيم للشقق والفلل ولنا خبرة طويلة في هذا المجال نخدم قطاع كبير من المؤسسات الحكومية والخاصة وإدارة الاماكن السياحية والمنشآت وكافة الخدمات المنزلية واحتياجات التنظيف على أعلى مستوى من الخبره والكفاءه العالية
                            </p>
                            <p>
                                قدم ايضاً خدمات مكافحة الحشرات على ايدى مهندسين وعمال ذات خبرات كبيره جدا فى مجال مكافحة الحشرات ويسعدنا عزيزي العميل ان تنضم الي عملائنا المميزين الذين تربطهم بالشركة علاقات طيبة ورائعة مبنية على الثقة المتناهية والدقة في تقديم الخدمات
                            </p>
                            <a className="btn" href="src/pages">المزيد</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AboutUs;
