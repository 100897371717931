import qrCitiesData from "../../../common/const/qa_new.json";

export const setUserOrder = (values,setValues,setUser,setNodesService) => {
    setUser(true)
    let userData = [
        {
            id: "1",
            doc_id: "name",
            title: "ادخل اسمك كامل",
            placeholder:"الاسم كامل",
            type: "text",
        },
        {
            id: "2",
            doc_id: "phone",
            title: "رقم الهاتف",
            placeholder:"ادخل رقم هاتف صحيح",
            type: "number",
        },
        {
            id: "3",
            doc_id: "city",
            title: "المدينة",
            type: "select",
            values: qrCitiesData
        },
        {
            id: "4",
            doc_id: "address",
            title: "العنوان",
            placeholder:"مثل: الشارع - رقم المبنى - رقم الشقة",
            type: "text",
        },
        {
            id: "5",
            doc_id: "payment_type",
            title: "الدفع",
            type: "radio",
            valuesTitle: ['اونلاين','كاش']
        },
    ]
    let list = []
    userData.forEach(value => {
        //set in begin list
        let listValues = [{
            id:"0",
            name: `اختر ${value.title} ..`,
            value: ""
        }]
        value.values?.forEach(item => {
            listValues.push({
                id: item.id,
                name: item.name,
                value: item.id,
                price:item.price
            })
        })
        if (value.type === "radio"){
            for (let i=0;i <= 1;i++){
                listValues.push({
                    id: value.id,
                    name: value.doc_id,
                    value: i+1
                })
            }
        }
        list.push({
            id: value.id,
            name:  value.doc_id,
            type: value.type,
            placeholder: value.placeholder?value.placeholder:` ${value.title}`,
            label: value.title,
            values: listValues,
            valuesTitle: value.valuesTitle,
            errorMessage: `ادخل ${value.title}`,
            required: true
        })
    })
    setNodesService(list)
    let valuesTemp = {}
    list.forEach((val)=> {
        valuesTemp[val.name] = ""
    })
    setValues({user: valuesTemp,orderData:values.orderData});
}
