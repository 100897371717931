import './footer.css'
import phone from "../../assets/icons/footer/phone-svgrepo-com.svg";
import instagram from "../../assets/icons/footer/instagram-alt-svgrepo-com.svg";
import whatsapp from "../../assets/icons/footer/whatsapp-128-svgrepo-com.svg";
function Footer() {
    return (
        <div className="footer">
            <div className="footer-contact">
                <h4>إِبقَ على تواصل/</h4>
                <div className="contact">
                    <a href="tel:+974 55400095">
                        <img className="icon" src={phone} alt="Logo" />
                        <p>+974 55400095</p>
                    </a>
                    <a href="https://www.instagram.com/alaman.qtr/">
                        <img className="icon" src={instagram} alt="Logo" />
                    </a>
                    <a href="whatsapp://send?abid=974 55400095&text=Hello%2C%20World">
                        <img className="icon" src={whatsapp} alt="Logo" />
                    </a>
                </div>
            </div>
            <div className="container copyright center">
                <p>Designed By Protocol Company</p>
            </div>
        </div>
    );
}

export default Footer;
