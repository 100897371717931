import {Radio, RadioGroup} from "./readio_group/radio_group";
import './form_radio.css'
function FormRadio(props) {
    const {label, errorMessage, onChange, id,values,valuesTitle, ...inputProps } = props;
    return (
        <div className="radio">
            <RadioGroup>
                <Radio value={values[2].value} onChange={onChange} id="basic" name={inputProps.name}>
                    <p>{valuesTitle?valuesTitle[0]:"مع "+label}</p>
                </Radio>
                <Radio value={values[1].value} onChange={onChange} id="advanced" name={inputProps.name}>
                    <p>{valuesTitle?valuesTitle[1]:"بدون "+label}</p>
                </Radio>
            </RadioGroup>
        </div>
    );
}

export default FormRadio;
