import React from 'react';
import './order_sent.css'
import {Link, useParams} from "react-router-dom";
// import Footer from "../../../components/footer/footer";
import BackButton from "../../../components/back/back-button";
const OrderSent = () => {
    const {type,id,paytype} = useParams()
    // navigate(`/invoice/${data.orderId}`)
    // const [payLoading, setPayLoading] = useState(false)
    return (
        <div>
            <BackButton />
            <div className="order-sent">
                <div className="svg-container">
                    <svg className="ft-green-tick" xmlns="http://www.w3.org/2000/svg" height="100" width="100"
                         viewBox="0 0 48 48" aria-hidden="true">
                        <circle className="circle" fill="#5bb543" cx="24" cy="24" r="22"/>
                        <path className="tick" fill="none" stroke="#FFF" strokeWidth="6" strokeLinecap="round"
                              strokeLinejoin="round" strokeMiterlimit="10" d="M14 27l5.917 4.917L34 17"/>
                    </svg>
                    <p>
                        {type === "1"?"تم ارسال رابط علي الايميل الخاص بك":"تم الحفظ"}
                    </p>
                    <h5 className="bold-text">
                        {type === "1"?"اذهب للايميل واضغط على رابط التحقق لاكمال العملية":+paytype === 1?"سيقوم فريقنا بالاتصال بك":""}
                    </h5>
                    <h5 className="text-center">
                        {type === "1"?"":+paytype === 1?"":"لاكمال الطلب قم بعرض الفاتورة من ثم اضفط على زر الدفع"}
                    </h5>
                    <div className="row-btns">
                        <div>
                            {type !== "1" && <Link className="btn btn-green" to={"/invoice/"+id}>عرض الفاتورة</Link>}
                        </div>
                        <div className="space-40"></div>
                        <div>
                            {type !== "1" && <Link className="btn" to="/">رجوع للرئيسية</Link>}
                        </div>
                    </div>
                </div>
            </div>
            {/*<Footer />*/}
        </div>
    );
};

export default OrderSent;
