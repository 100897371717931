import React, {useEffect, useState} from 'react';
import "./skeleton.css"
const Skeleton = ({length= 2}) => {
    const [list,setList] = useState([])
    useEffect(()=>{
        let listTemp = []
        for(let i = 1; i <= length; i++ ){
            listTemp.push(i)
            console.log(i)
            setList(listTemp)
        }
    },[])
    return (
        <div className="skeleton-container">
            {list.map((value,index)=>
                <div key={index} className="skeleton skeleton-text"></div>)}
        </div>
    );
};

export default Skeleton;
