import React from 'react';
import {Link} from "react-router-dom";
import {ApiFunctions} from "../../common/api_funcotions/api_functions";

const ContactIcons = ({white}) => {
    const whatsappLink = "whatsapp://send?text=&phone=+97455400095"
    const apiFunction = new ApiFunctions()

    return (
        <div  className={white?"header-icons icons-pr-8":"header-icons icons-pr-5"}>
            {/*<Link to={apiFunction.loggedIn()?"/profile":"/login"}>*/}
            {/*    <i className={white?"icon white login-text":"icon login-text"}>*/}
            {/*        {apiFunction.loggedIn()?"حسابي":"تسجيل"}*/}
            {/*    </i>*/}
            {/*</Link>*/}
            <Link target="_blank" to="https://www.instagram.com/alaman.qtr/">
                <i className={white?"fa-brands fa-instagram icon white":"fa-brands fa-instagram icon"}></i>
            </Link>
            <Link target="_blank" to={whatsappLink}>
                <i className={white?"fa-brands fa-whatsapp icon white":"fa-brands fa-whatsapp icon"}></i>
            </Link>
            <Link target="_blank" to="https://alaman-qa.com/الامان للتنظيفات.pdf">
                <i className={white?"fa-solid fa-file-pdf icon white":"fa-solid fa-file-pdf icon"}></i>
            </Link>
        </div>
    );
};

export default ContactIcons;
