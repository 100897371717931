import React, {useEffect} from 'react';
import HeaderHome from "../components/header/header-home/header_home";
import AboutUs from "../pages/aboute/about";
import Services from "../pages/services/services";
import Details from "../pages/services/details/details";
import Feature from "../components/feature/feature";
import OurWorks from "../pages/our_works/our_works";
import Footer from "../components/footer/footer";
import Navbar from "../components/navbar/navbar";

const HomePage = () => {
    useEffect(()=>{
        window.history.replaceState({}, document.title)
    },[])
    return (
        <>
            <Navbar />
            <HeaderHome />
            <AboutUs />
            <Services />
            <Details />
            <Feature />
            <OurWorks />
            <Footer />
        </>
    );
};

export default HomePage;
