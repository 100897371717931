import './steps_form.css'
function StepsForm({current}) {
    return (
        <div>
            <section className="step-wizard">
                <ul className="step-wizard-list">
                    <li className={current === 1?
                        "step-wizard-item current-item":"step-wizard-item"}>
                        <span className="progress-count">1</span>
                        <span className="progress-label">بيانات الطلب</span>
                    </li>
                    <li className={current === 2?
                        "step-wizard-item current-item":"step-wizard-item"}>
                        <span className="progress-count">2</span>
                        <span className="progress-label">بيانات العميل</span>
                    </li>
                </ul>
            </section>
        </div>
    );
}

export default StepsForm;
