import React, {useContext, useEffect, useRef, useState} from 'react';
import "./navbar.css"
import {Link, useLocation} from "react-router-dom";
import logo from "../../assets/images/header/alamanlogo.png";
import ContactIcons from "../header/contact-icons";
import {AppContext} from "../../Home/home_page";
const Navbar = () => {
    const [white,setWhite] = useState(true)
    const location = useLocation();
    console.log(location.pathname);
    window.onscroll = function() {
        let navbar
        let logo
        let ul
        let span
        if (document.getElementById("nav")){
            navbar = document.getElementById("nav")
            logo = document.getElementById("logo")
            ul = document.getElementById("ul")
            span = document.getElementById("span")
        }
        if(window.scrollY === 0 && document.getElementById("nav")){
            navbar.style.backgroundColor = "transparent"
            // ul.style.backgroundColor = "transparent"
            navbar.style.boxShadow = "none"
            span.style.setProperty("--colorValue","#fff")
            setWhite(true)

            logo.style.setProperty("--brandBg","white")

            logo.style.setProperty("--scaleLogo","1")
            logo.style.setProperty("--brandMoving","-2rem")

            logo.style.setProperty("--brandScaleMobile",".6")
            logo.style.setProperty("--brandMovingMobile","-4rem")

            ul.style.setProperty("--ulBg","transparent")
            ul.style.setProperty("--ulBgMobile","var(--primary)")

        } else if (document.getElementById("nav")) {
            navbar.style.backgroundColor = "#fff"
            navbar.style.boxShadow = "1px 1px 4px 0 rgba(0,0,0,.1)"
            span.style.setProperty("--colorValue","#333")
            setWhite(false)

            logo.style.setProperty("--scaleLogo",".34")
            logo.style.setProperty("--brandBg","transparent")
            logo.style.setProperty("--brandMoving","-5rem")

            logo.style.setProperty("--brandScaleMobile",".34")
            logo.style.setProperty("--brandMovingMobile","-5.2rem")

            ul.style.setProperty("--ulBg","#fff")
            ul.style.setProperty("--ulBgMobile","#fff")
        }
    }

    const profileLink = "https://protocolqa.com/profiles/%D8%A8%D8%B1%D9%88%D8%AA%D9%88%D9%83%D9%88%D9%84%20%D9%84%D9%84%D8%A7%D8%B3%D8%AA%D8%B4%D8%A7%D8%B1%D8%A7%D8%AA%20%D9%88%D8%A7%D9%84%D8%AA%D8%AF%D8%B1%D9%8A%D8%A8.pdf"
    return (
        <header id="nav" className="navbar">
            <div id="logo" className="brand">
                <img src={logo} alt="Logo" />
            </div>
            <ContactIcons white={white}/>
            <input className="menu-btn" type="checkbox" id="menu-btn"/>
            <label className="menu-icon" htmlFor="menu-btn"><span id="span" className="navicon"></span></label>
            <ul id="ul" className="menu">
                <li><a className={white?"white":""} href="#">الرئيسية</a></li>
                <li><a className={white?"white":""} href="#aboute">عن الشركة</a></li>
                <li><a className={white?"white":""} href="#services">خدماتنا</a></li>
                <li><a className={white?"white":""} href="#why-us">لماذا نحن؟</a></li>
                <li className="download-pdf">
                    <Link className={white?"download-pdf white":"download-pdf"}
                          to={profileLink}>تنزيل البوفايل</Link></li>
            </ul>
        </header>
    );
};

export default Navbar;
