import React from 'react';
import tourists from '../../assets/images/why_we/tourists.jpg'
import palance from '../../assets/images/why_we/palance.jpg'
import './feature.css'

const Feature = () => {
    return (
        <div id="why-us" className="feature">
            <div className="container">
                <div className="row">
                    <div className="pl-3">
                        <div className="section-header">
                            <h2>لماذا تختارنا؟</h2>
                        </div>
                        <h3 className="primary-color">عمَّال نظافة خبراء وخدمات عالمية المستوى</h3>
                        <p>
                            تمتلك الشركة متخصصين وخبراء في مجال النظافة، ليس لتدريب العمالة فقط ولكن للإشراف على فريق
                            العمل اثناء وبعد الانتهاء من عملية التنظيف، لضمان تسليم المنزل أو الشركة أو المؤسسة للعميل
                            وهي في أفضل صورة.
                        </p>
                        <p>
                            تقدم شركة الأمان خدماتها المختلفة بأسعار مناسبة لكافة فئات المجتمع، وتقدم لعملائها إمكانية
                            التعاقد بشكل سنوي أو نصف سنوي، أو شهري بأسعار خاصة، وتتيح عروضًا مميزة لعملائها الجدد.
                        </p>
                    </div>
                    <div>
                        <div className="row feature-item">
                            <img src={tourists} alt="Feature" />
                            <div className="text-context">
                                <h5 className="primary-color bold-text">من أكثر شركات التنظيفات الموثوقة في قطر</h5>
                                <p>
                                    فالعملاء الذين جربوا العمل معنا و لو لمرة واحدة يعرفون جيدا أننا محل ثقة، و في
                                    المقابل نبذل كل ما في جهدنا حتى نحافظ على ثقتهم تلك على المدى الطويل.
                                </p>
                            </div>
                        </div>
                        <div className="row feature-item">
                            <img src={palance} alt="Feature" />
                            <div className="text-context">
                                <h5 className="primary-color bold-text">نقدم خدمات تنظيف حسب احتياجاتك</h5>
                                <p>
                                    عندما تقوم بالعمل معنا، ثق بأن ماتريد عمله فوق كل اعتبار لدينا، فقبل أن نبدأ التنظيف
                                    نحرص على تخصيص الوقت الكافي للاستماع إلى احتياجاتك و مناقشة أولوياتك و خصوصياتك.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
);
};

export default Feature;
