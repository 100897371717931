import {baseUrl} from "../const/consts";
import axios from "axios";

export  class ApiFunctions {

     getServices() {
        return new Promise(async function (resolve, reject) {
            let res = await axios.post(baseUrl, {table: "categories"});
            if (res.status === 200) {
                resolve(res.data);
            } else {
                reject();
            }
        })
    }


     async saveData(values) {
         let res = await axios.post(`${baseUrl}insert/order/`, values);
        // console.log(res)
        if (res.status === 200) {
            return res.data;
        } else {
            return `HTTP error: ${res.status}`;
        }
    }

    setCookie(cname, cvalue, exdays) {
        const d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        let expires = "expires="+d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }
    getCookie(cname) {
        let name = cname + "=";
        let ca = document.cookie.split(';');
        for(let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    loggedIn = () => this.getCookie("uid") !== "" && this.getCookie("uid") !== null
    getUserId = () => this.getCookie("uid")
    setCUserId(uid){
         this.setCookie("uid",uid,2)
    }
}
